






















import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Emit } from 'vue-property-decorator';

// The @Component decorator indicates the class is a Vue component
@Component({})
export default class BaseHeader extends Vue {
  @Prop()
  title!: string;

  @Prop()
  subtitle!: string;

  @Prop({
    default: true,
  })
  showMain!: boolean;

  @Prop({
    default: 'plus',
  })
  mainIcon!: string;

  @Prop()
  mainText!: string;

  @Prop({
    default: 'primary',
  })
  mainVariant!: string;

  @Emit('main')
  onMainClicked() {
    return true;
  }
}
