






























































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Emit, Prop } from 'vue-property-decorator';
import { Column } from '../shared/types/column.class';
import BaseTableFilterButton from './BaseTableFilterButton.vue';

export class BaseTableTab {
  constructor(public event: string, public label: string) {}
}

// The @Component decorator indicates the class is a Vue component
@Component({ components: { BaseTableFilterButton } })
export default class BaseTable extends Vue {
  @Prop({
    type: Array,
  })
  tabs!: BaseTableTab[];

  @Prop()
  currentTab!: string;

  @Prop()
  title!: string;

  @Prop()
  columns!: Column[];

  @Prop({
    default: true,
  })
  canUpdate!: boolean;

  @Prop({
    default: true,
  })
  canDelete!: boolean;

  @Prop({
    default: true,
  })
  canSearch!: boolean;

  @Prop()
  elements!: unknown[];

  @Prop()
  totalDocs!: number;

  @Prop()
  page!: number;

  @Prop()
  limit!: number;

  @Prop()
  customButtons!: { variant: string; icon: string; event: string }[];

  @Prop({
    default: false,
  })
  isLoading!: boolean;

  @Prop({
    default: false,
  })
  embedded!: boolean;

  @Prop({
    default: false,
  })
  showFilter!: boolean;

  @Prop()
  filterCount!: number;

  @Prop({
    default: false,
  })
  checkable!: boolean;

  @Prop({
    default: false,
  })
  checkAll!: boolean;

  @Prop({
    default: 'active',
  })
  checkedField!: string;

  searchString = '';

  get showHeader() {
    return this.title || this.tabs || this.canSearch;
  }

  get showPagination() {
    return this.limit <= this.totalDocs;
  }

  @Emit('search')
  doSearch(search: string) {
    return search;
  }

  @Emit('sort')
  doSort(sortField: string) {
    return sortField;
  }

  @Emit('edit')
  doEdit(element: unknown) {
    return element;
  }

  @Emit('delete')
  doDelete(element: unknown) {
    return element;
  }

  @Emit('pageChanged')
  onPageChanged(page: number) {
    return page;
  }

  @Emit('tabChanged')
  onTabSelected(tab: BaseTableTab) {
    return tab.event;
  }

  @Emit('filterClicked')
  onFiltersClicked() {
    return true;
  }

  @Emit('checked')
  select(state: boolean, element: any) {
    return { state, element };
  }

  @Emit('checkedAll')
  selectAll(value) {
    return value;
  }

  emit(event: string, element: unknown) {
    this.$emit(event, element);
  }
}
