
















import Vue from 'vue';
import Component from 'vue-class-component';
import { Emit, Prop } from 'vue-property-decorator';

// The @Component decorator indicates the class is a Vue component
@Component({})
export default class BaseTableFilterButton extends Vue {
  @Prop()
  filterCount!: number;

  @Emit('click')
  onFiltersClicked() {
    return true;
  }
}
