import { SortOrder } from './sort-order.enum';

export enum CastType {
  Number = 'Number',
  Date = 'Date',
  Boolean = 'Boolean',
  ObjectId = 'ObjectId',
}

export enum Operator {
  Equal = '=',
  GreaterThan = '>',
  GreaterThanOrEqual = '>=',
  LessThan = '<',
  LessThanOrEqual = '<=',
  Unequal = '!=',
  Exists = '',
  DoesNotExist = '!',
}
export class FilterOperation {
  constructor(
    public key: string,
    public value?: number | string | boolean | number[] | string[],
    public operator: Operator = Operator.Equal,
    public type?: CastType,
  ) {}

  transformOperator(key: string) {
    return key;
  }
}

export class Filter {
  constructor(
    public query: FilterOperation[] = [],
    public sort?: { [key: string]: SortOrder },
    public populate?: string[],
  ) {
    if (!this.hasKey('limit')) {
      this.addFilterOperation(new FilterOperation('limit', 25));
    }
  }

  get count(): number {
    return this.query.length;
  }

  setSearch(value?: string): void {
    if (!value || value === '') {
      this.removeForKey('$search');
      return;
    }
    this.replaceFilterOperation(new FilterOperation('$search', value));
  }

  hasKey(key: string): boolean {
    return this.query.some(x => x.key === key);
  }

  indexOfKey(key: string): number {
    return this.query.findIndex(x => x.key === key);
  }

  firstForKey(key: string): FilterOperation {
    return this.query.find(x => x.key === key);
  }

  removeForKey(key: string): void {
    while (key) {
      const index = this.query.findIndex(x => x.key === key);
      if (index === -1) {
        return;
      }
      this.query.splice(index, 1);
    }
  }

  removeForKeyAndOperator(key: string, operator: Operator): void {
    while (key) {
      const index = this.query.findIndex(
        x => x.key === key && x.operator === operator,
      );
      if (index === -1) {
        return;
      }
      this.query.splice(index, 1);
    }
  }

  replaceFilter(
    key: string,
    value?: number | string | boolean | number[] | string[],
    operator: Operator = Operator.Equal,
    cast?: CastType,
  ): void {
    this.removeForKey(key);
    this.addFilter(key, value, operator, cast);
  }

  replaceFilterOperation(operation: FilterOperation): void {
    this.removeForKey(operation.key);
    this.addFilterOperation(operation);
  }

  addFilter(
    key: string,
    value?: number | string | boolean | number[] | string[],
    operator: Operator = Operator.Equal,
    cast?: CastType,
  ) {
    this.addFilterOperation(new FilterOperation(key, value, operator, cast));
  }

  addFilterOperation(operation: FilterOperation): void {
    this.query.push(operation);
  }

  setSort(field: string): void {
    if (!this.sort) {
      this.sort = {};
    }
    const currentOrder = this.sort[field];
    this.sort = {};
    if (!currentOrder || currentOrder === SortOrder.Desc) {
      this.sort[field] = SortOrder.Asc;
    } else if (currentOrder === SortOrder.Asc) {
      this.sort[field] = SortOrder.Desc;
    }
  }

  transformQuery(): string {
    return this.query
      .filter((filterOperation: FilterOperation) => filterOperation.value)
      .map((filterOperation: FilterOperation) => {
        const { key, value, operator, type } = filterOperation;
        if (
          operator === Operator.Exists ||
          operator === Operator.DoesNotExist
        ) {
          return `${operator}${key}`;
        }
        let operatorValue = value;
        if (value instanceof Array) {
          if (value.length > 1) {
            operatorValue = value.join(',');
          } else if (value.length === 1) {
            operatorValue = [value[0], value[0]].join(',');
          }
        }
        if (type) {
          operatorValue = `${type}(${operatorValue})`;
        }

        return `${key}${operator}${operatorValue}`;
      })
      .join('&');
  }

  URIEncode(): string {
    return this.transformQuery();
  }

  transformSort(): string {
    if (!this.sort) {
      return '';
    }
    return Object.entries(this.sort)
      .map(([key, value]) => `${value === -1 ? '-' : ''}${key}`)
      .join(',');
  }

  toParams() {
    return {
      sort: this.transformSort(),
      populate: this.populate?.join(','),
    };
  }

  copy(): Filter {
    const filter = new Filter();
    filter.query = [...this.query];
    if (this.sort) {
      filter.sort = { ...this.sort };
    }
    if (this.populate) {
      filter.populate = [...this.populate];
    }
    return filter;
  }
}
