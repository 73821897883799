import moment from 'moment-timezone';
import * as _ from 'lodash';
import { Column } from '../types/column.class';
import { User } from '@/api/users/user.class';
import { Booking } from '@/api/bookings/booking.model';

export function formatDate(column: Column, obj: unknown) {
  return `${moment
    .utc(_.get(obj, column.field))
    .format('ddd, DD-MM-YYYY HH:mm')}`;
}

export function formatDateBold(column: Column, obj: unknown) {
  return `<b>${moment
    .utc(_.get(obj, column.field))
    .format('ddd, DD-MM-YYYY HH:mm')}</b>`;
}

export function formatPrice(column: Column, obj: unknown) {
  const value = _.get(obj, column.field);
  if (!value) {
    return 'N/A';
  }
  return value.toFixed(2);
}

export function formatUser(column: Column, obj: unknown) {
  const user: User = _.get(obj, column.field);

  return user?.email || 'Unknown';
}

export function formatAssetDate(column: Column, booking: Booking) {
  const date = moment.utc(_.get(booking, column.field));
  if (!date || !booking || !booking.asset || !booking.asset.timezone) {
    return 'N/A';
  }
  const dateInAssetTimezone = date.tz(booking.asset.timezone);
  return dateInAssetTimezone.format('ddd, DD-MM-YYYY HH:mm');
}
